import { Component,  } from '@angular/core';
// import firebase from 'firebase';
import * as environment from '../environments/environment';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor() {
        if (!environment.environment.production) {
            setTimeout(() => {
                // firebase.functions().useFunctionsEmulator('http://localhost:5000');
            }, 1000);
        }
    }
}
