import { AuthService } from '../services/auth.service';
import { MasterDataService } from './master-data.service';
import { Subscription, Observable, BehaviorSubject, combineLatest, } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import { DataScope, User } from './interfaces.service';
import { switchMap, filter,  tap, } from 'rxjs/operators';
import { MessageService, SelectItem } from 'primeng/api';

import {
  Firestore, //constructor
  collection,
  doc,
  collectionData, //subscription
  query, // query<Cast>(collection, where()), {idField: 'id'})
  where,
  orderBy,
  getDoc, //promise
  addDoc,
  setDoc,
  getDocs, //batch
} from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class ScopesService implements OnDestroy {

  private _userDetailSub: Subscription;

  private _activeScopeSub: Subscription;
  public activeScopeId = new BehaviorSubject<string>('super'); // gets called when signing in or dropdown
  public activeScope = new BehaviorSubject<DataScope>({});

  private _scopeCollection: any;

  public scopesBS = new BehaviorSubject<DataScope[]>([]); // To manage scopes
  private _scopesSub: Subscription;


  constructor(private authService: AuthService,
              // private afs: AngularFirestore,
              private afs: Firestore,
              private messageService: MessageService,
              private masterDataService: MasterDataService) {

  this._scopeCollection = collection(this.afs, '/Scopes');

  getDocs(this._scopeCollection)
    .then(snapshot => {
      let scopes: DataScope[] = [];
      snapshot.forEach(doc => {
        scopes.push(doc.data() as DataScope);
      });
      this.scopesBS.next(scopes);
    });
  
  this._scopesSub = this.scopesBS.asObservable()
    .pipe(tap(scopes => {
      if (scopes.length > 0) {
        this.activeScope.next(scopes.find(el => {
          return el.id === this.activeScopeId.value;
        }));
      }
    }))
    .subscribe();

    this._userDetailSub = this.authService.userDetailsObs
      .subscribe((user: User) => {
        if (user.id) {
          this.activeScopeId.next(user.scopeId[0]);
        } else {
          this.activeScope.next({});
        }
      });

    this._activeScopeSub = combineLatest([this.activeScopeId, this.scopesBS])
      .subscribe(([activeScopeId, scopes]) => {
        if (scopes.length > 0) {
          this.activeScope.next(scopes.find(el => {
            return el.id === activeScopeId;
          }));
        }
      });
  }



  public getScope(scopeId: string): Promise<DataScope> {
    return new Promise(async resolve => {
      resolve(this.scopesBS.value.find(el => el.id === scopeId));
    });
  }

  async updateScope(id: string, scopeObj: DataScope) {

    const updateObj: DataScope = {
      ...scopeObj,
      updatedOn: new Date(),
    };
    try {
      // await this.afs.collection('/Scopes').doc(id).update(updateObj); // TODO check fs
      await setDoc(doc(this.afs, 'Scopes', id), updateObj, { merge: true });
    } catch {
      this._handleError();
      throw new Error('error');
    }

  }

  async addScope(scopeObj: DataScope) {
    // const id = this.afs.createId(); // TODO check fs
    const id = doc(collection(this.afs, 'Scopes')).id;
    const practiceType = this.masterDataService.getPracticeTypeLabel(scopeObj.practiceTypeId);
    const newObj: DataScope = {
      ...scopeObj,
      id,
      practiceType,
      createdOn: new Date(),
    };
    try {
      // await this.afs.collection('/Scopes').doc(id).set(newObj); // TODO check fs
      await addDoc(collection(this.afs, 'Scopes'), newObj);
    } catch {
      this._handleError();
      throw new Error('error');
    }
  }

  // public getScopesDropDown(): Promise<SelectItem[]> {
  //   return new Promise(resolve => {
  //     if (this._scopes.length > 0) {
  //       resolve(this._scopes.map(el => {
  //         return {value: el.id, label: el.name};
  //       }));
  //     } else {
  //       this.scopesObs.toPromise()
  //         .then(scopes => {
  //           resolve(scopes.map(el => {
  //             return {value: el.id, label: el.name};
  //           }));
  //         });
  //     }
  //   });
  // }

  private _handleError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please try again later' });
  }

  ngOnDestroy() {
    if (this._userDetailSub) {
      this._userDetailSub.unsubscribe();
    }
    if (this._scopesSub) {
      this._scopesSub.unsubscribe();
    }
    if (this._activeScopeSub) {
      this._activeScopeSub.unsubscribe();
    }

  }
}
