import { ThemeService } from './../services/theme.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { User } from '../services/interfaces.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit, OnDestroy {
  userSub: Subscription;
  user: User = {
    profileImageUrl: '/assets/images/person_placeholder.png',
    nameSurname: '',
    superAdmin: false
  };

  constructor(public themeService: ThemeService,
              private authService: AuthService) { }

  ngOnInit() {
    this.userSub = this.authService.userDetailsObs
      .subscribe((user: User) => {
        if (user) {
          this.user.profileImageUrl = user.profileImageUrl ? user.profileImageUrl : '/assets/images/person_placeholder.png';
          this.user.nameSurname = user.nameSurname;
          this.user.superAdmin = user.superAdmin;
        }
      });
  }

  onMenuButtonClick(event) {
    this.themeService.menuClick = true;
    this.themeService.topbarMenuActive = false;

    if (this.themeService.isOverlay()) {
      this.themeService.overlayMenuActive = !this.themeService.overlayMenuActive;
    }
    if (this.themeService.isDesktop()) {
      this.themeService.staticMenuDesktopInactive = !this.themeService.staticMenuDesktopInactive;
    } else {
      this.themeService.staticMenuMobileActive = !this.themeService.staticMenuMobileActive;
    }

    event.preventDefault();
  }

  onTopbarMenuButtonClick(event) {
    this.themeService.topbarItemClick = true;
    this.themeService.topbarMenuActive = !this.themeService.topbarMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarItemClick(event, item) {
    this.themeService.topbarItemClick = true;

    if (this.themeService.activeTopbarItem === item) {
      this.themeService.activeTopbarItem = null;
    } else {
      this.themeService.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onTopbarSubItemClick(event) {
    event.preventDefault();
  }

  hideOverlayMenu() {
    this.themeService.overlayMenuActive = false;
    this.themeService.staticMenuMobileActive = false;
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }
}
