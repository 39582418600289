import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public darkTheme = true;
  public menuMode = 'static';
  menuHoverActive: boolean;
  overlayMenuActive: boolean;
  staticMenuMobileActive: boolean;
  resetMenu: boolean;
  moveLayoutMenuScrollBar = new Subject<string>();
  menuClick: boolean;
  topbarMenuActive: boolean;
  topbarItemClick: boolean;
  activeTopbarItem: any;
  staticMenuDesktopInactive: boolean;

  constructor() { }

  public changeTheme(theme) {
    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    themeLink.href = 'assets/theme/theme-' + theme + '.css';
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
    layoutLink.href = 'assets/theme/layout-' + theme + '.css';

    if (theme.indexOf('dark') !== -1) {
      this.darkTheme = true;
    } else {
      this.darkTheme = false;
    }
  }

  isHorizontal() {
    return this.menuMode === 'horizontal';
  }

  isSlim() {
    return this.menuMode === 'slim';
  }

  isOverlay() {
    return this.menuMode === 'overlay';
  }

  isStatic() {
    return this.menuMode === 'static';
  }

  isMobile() {
    return window.innerWidth < 1025;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }
}
