import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppFooterComponent } from './app.footer.component';
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppTopBarComponent } from './app.topbar.component';
import { LayoutHomeComponent } from './layout-home.component';



@NgModule({

    declarations: [
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppBreadcrumbComponent,
        LayoutHomeComponent,
    ],
    imports: [
        CommonModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ToastModule, 
        DropdownModule,
        RouterModule,
        ScrollPanelModule,
    ],
    providers: [],
})
export class LayoutModule { }
