import { MessageService, SelectItem } from 'primeng/api';
import { Age, AnatomyCategory, ComparisonGroup, EquipmentCategory, FitnessCategory, SportFilter, TestCategory } from './interfaces.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap, take } from 'rxjs/operators';

import {
  Firestore, //constructor
  collection,
  doc,
  collectionData, //subscription
  query, // query<Cast>(collection, where()), {idField: 'id'})
  where,
  orderBy,
  getDoc, //promise
  addDoc,
  setDoc,
  getDocs, //batch
} from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { writeBatch } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  public agesObs: Observable<Age[]>;
  private _ageGroups: Age[] = [];

  public sportFiltersObs: Observable<SportFilter[]>;
  private _sportFilterGroups: SportFilter[] = [];

  public comparisonGroupObs: Observable<ComparisonGroup[]>;
  private _comparisonGroups: ComparisonGroup[] = [];

  public testCategories$: Observable<FitnessCategory[]>;
  private _testCategories: FitnessCategory[] = [];
  
  public anatomyCategories$: Observable<AnatomyCategory[]>;
  private _anatomyCategories: AnatomyCategory[] = [];
  
  public equipmentCategories$: Observable<EquipmentCategory[]>;
  

  constructor(
    // private afs: AngularFirestore,
    private afs: Firestore,
    private messageService: MessageService) {
    console.log('starting');

    // this.agesObs = this.afs.collection('Ages', ref => ref.where('status', '==', 'active').orderBy('order')) // TODO check fs
    //   .valueChanges({ idField: 'id' })
    //   .pipe(tap(ageGroups => {
    //     this._ageGroups = ageGroups;
    //   }));
    this.agesObs = collectionData(query(collection(this.afs, 'Ages'), 
      where ('status', '==', 'active'),
      orderBy('order')),
      { idField: 'id' })
      .pipe(tap(ageGroups => {
        this._ageGroups = ageGroups;
      }));


    this.sportFiltersObs = collectionData(query(collection(this.afs, 'SportFilters'),
      where('status', '==', 'active'),
      orderBy('order')),
      { idField: 'id' })
      .pipe(tap(groups => {
        this._sportFilterGroups = groups;
      }));

    // tslint:disable-next-line:max-line-length
    // this.comparisonGroupObs = this.afs.collection('ComparisonGroups', ref => ref.where('status', '==', 'active').orderBy('order')) // TODO check fs
      // .valueChanges({ idField: 'id' })
      // .pipe(tap(groups => {
      //   this._comparisonGroups = groups;
      // }));
    this.comparisonGroupObs = collectionData(query(collection(this.afs, 'ComparisonGroups'), 
      where('status', '==', 'active'),
      orderBy('order')),
      { idField: 'id' })
      .pipe(tap(groups => {
        this._comparisonGroups = groups;
      }));


    // tslint:disable-next-line:max-line-length
    // this.testCategories$ = this.afs.collection('TestCategories', ref => ref.orderBy('order').where('active', '==', true)) // TODO check fs
    //   .valueChanges({ idField: 'id' })
    //   .pipe(tap(categories => {
    //     this._testCategories = categories;
    //   }));
    this.testCategories$ = collectionData(query(collection(this.afs, 'TestCategories'), 
      orderBy('order'), where('active', '==', true)),
      { idField: 'id' })
      .pipe(tap(categories => {
        this._testCategories = categories;
      }));
    
    // this.anatomyCategories$ = this.afs.collection('AnatomyCategories', 
    //   ref => ref.orderBy('order').where('active', '==', true)).valueChanges({ idField: 'id' }) // TODO check fs
    //   .pipe(tap(entries => {
    //     this._anatomyCategories = entries;
    //   }));
    this.anatomyCategories$ = collectionData(query(collection(this.afs, 'AnatomyCategories'), 
      orderBy('order'), where('active', '==', true)),
      { idField: 'id' })
      .pipe(tap(entries => {
        this._anatomyCategories = entries;
      }));
    
    // this.equipmentCategories$ = this.afs.collection('EquipmentCategories', 
    //   ref => ref.orderBy('order').where('active', '==', true)).valueChanges({ idField: 'id' }); // TODO check fs
    this.equipmentCategories$ = collectionData(query(collection(this.afs, 'EquipmentCategories'), 
      orderBy('order'), where('active', '==', true)),
      { idField: 'id' });
      
  }

  public addAge(addObj: Age) {
    return new Promise((resolve, reject) => {
      const newObj: Age = Object.assign({}, addObj, { status: 'active' });
      // this.afs.collection('/Ages').add(newObj) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      addDoc(collection(this.afs, 'Ages'), newObj)
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public editAge(id: string, editObj: Age) {
    return new Promise((resolve, reject) => {
      // this.afs.collection('/Ages').doc(id).update(editObj) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `Ages/${id}`), editObj, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public archiveAge(id: string) {
    return new Promise((resolve, reject) => {
      // this.afs.collection('/Ages').doc(id).update({ status: 'archived' }) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `Ages/${id}`), { status: 'archived' }, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public changeAgeOrder(ages: Age[]): Promise<string> {
    return new Promise((resolve, reject) => {
      // const batch = this.afs.firestore.batch(); // TODO check fs
      // const ref = this.afs.firestore.collection('/Ages');
      const batch = writeBatch(this.afs)
      const ref = collection(this.afs, 'Ages');
      ages.forEach((item, index) => {
        if (item.order !== index) {
          // batch.update(ref.doc(item.id), { order: index }); // TODO check fs
          batch.update(doc(ref, item.id), { order: index });
        }
      });
      batch.commit()
        .then(() => {
          resolve('success');
        })
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }


  public getAgeGroupDropdown(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      if (this._ageGroups.length > 0) {
        resolve(this._ageGroups.map(el => {
          return { value: el.id, label: el.name };
        }));
      } else {
        this.agesObs.pipe(take(1)).toPromise()
          .then(ageGroups => {
            resolve(ageGroups.map(el => {
              return { value: el.id, label: el.name };
            }));
          }).catch(() => {
            reject('error');
            this._handleError();
          });
      }
    });
  }
  public getAgeGroupLabel(id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this._ageGroups.length > 0) {
        resolve(this._ageGroups.find(el => {
          return el.id === id;
        }).name);
      } else {
        this.agesObs.pipe(take(1)).toPromise()
          .then(ageGroups => {
            resolve(ageGroups.find(el => {
              return el.id === id;
            }).name);
          }).catch(() => {
            reject('error');
            this._handleError();
          });
      }
    });
  }
  public addSportFilter(addObj: SportFilter) {
   return new Promise((resolve, reject) => {
     const newObj: SportFilter = Object.assign({}, addObj, { status: 'active' });
     addDoc(collection(this.afs, 'SportFilters'), newObj)
       .then(() => resolve('success'))
       .catch(() => {
         reject('error');
         this._handleError();
       });
   });
 }
 
 public editSportFilter(id: string, editObj: SportFilter) {
   return new Promise((resolve, reject) => {
     setDoc(doc(this.afs, `SportFilters/${id}`), editObj, { merge: true })
       .then(() => resolve('success'))
       .catch(() => {
         reject('error');
         this._handleError();
       });
   });
 }

  public archiveSportFilter(id: string) {
    return new Promise((resolve, reject) => {
      setDoc(doc(this.afs, `SportFilters/${id}`), { status: 'archived' }, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }


  public changeSportFilterOrder(groups: SportFilter[]): Promise<string> {
    return new Promise((resolve, reject) => {
      const batch = writeBatch(this.afs);
      const ref = collection(this.afs, 'SportFilters');
      groups.forEach((item, index) => {
        if (item.order !== index) {
          batch.update(doc(ref, item.id), { order: index });
        }
      });
      batch.commit()
        .then(() => {
          resolve('success');
        })
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public getSportFilterDropDown(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      if (this._sportFilterGroups.length > 0) {
        resolve(this._sportFilterGroups.map(el => {
          return { value: el.id, label: el.name };
        }));
      } else {
        this.sportFiltersObs.pipe(take(1)).toPromise()
          .then(groups => {
            resolve(groups.map(el => {
              return { value: el.id, label: el.name };
            }));
          }).catch(() => {
            reject('error');
            this._handleError();
          });
      }
    });
  }

  public getSportFilterGroupLabel(id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this._sportFilterGroups.length > 0) {
        resolve(this._sportFilterGroups.find(el => {
          return el.id === id;
        }).name);
      } else {
        this.sportFiltersObs.pipe(take(1)).toPromise()
          .then(groups => {
            resolve(groups.find(el => {
              return el.id === id;
            }).name);
          }).catch(() => {
            reject('error');
            this._handleError();
          });
      }
    });
  }

  // public getSportFilters(): Promise<SportFilter[]> {
  //   return new Promise((resolve, reject) => {
  //     if (this._sportFilterGroups.length > 0) {
  //       resolve(this._sportFilterGroups);
  //     } else {
  //       this.sportFiltersObs.pipe(take(1)).toPromise()
  //         .then(groups => {
  //           resolve(groups);
  //         }).catch(() => {
  //           reject('error');
  //           this._handleError();
  //         });
  //     }
  //   });
  // }





  public addComparisonGroup(addObj: ComparisonGroup) {
    return new Promise((resolve, reject) => {
      const newObj: ComparisonGroup = Object.assign({}, addObj, { status: 'active' });
      // this.afs.collection('/ComparisonGroups').add(newObj) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      addDoc(collection(this.afs, 'ComparisonGroups'), newObj)
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public editComparisonGroup(id: string, editObj: ComparisonGroup) {
    return new Promise((resolve, reject) => {
      // this.afs.collection('/ComparisonGroups').doc(id).update(editObj) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `ComparisonGroups/${id}`), editObj, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public archiveComparisonGroup(id: string) {
    return new Promise((resolve, reject) => {
      // this.afs.collection('/ComparisonGroups').doc(id).update({ status: 'archived' }) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `ComparisonGroups/${id}`), { status: 'archived' }, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public changeComparisonGroupOrder(groups: ComparisonGroup[]): Promise<string> {
    return new Promise((resolve, reject) => {
      // const batch = this.afs.firestore.batch();
      // const ref = this.afs.firestore.collection('/ComparisonGroups'); // TODO check fs
      const batch = writeBatch(this.afs)
      const ref = collection(this.afs, 'ComparisonGroups');
      groups.forEach((item, index) => {
        if (item.order !== index) {
          // batch.update(ref.doc(item.id), { order: index }); // TODO check fs
          batch.update(doc(ref, item.id), { order: index });
        }
      });
      batch.commit()
        .then(() => {
          resolve('success');
        })
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public getComparisonGroupsDropDown(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      if (this._comparisonGroups.length > 0) {
        resolve(this._comparisonGroups.map(el => {
          return { value: el.id, label: el.name };
        }));
      } else {
        this.comparisonGroupObs.pipe(take(1)).toPromise()
          .then(groups => {
            resolve(groups.map(el => {
              return { value: el.id, label: el.name };
            }));
          }).catch(() => {
            reject('error');
            this._handleError();
          });
      }
    });
  }




  public async getPossibleNormsDropDown() {
    const ages = await this.getAgeGroupDropdown();
    const comparisonGroups = await this.getComparisonGroupsDropDown();

    return [
      ...ages,
      ...comparisonGroups
    ];
  }


  public async addTestCategory(addObj: TestCategory) {
    try {

      const newObj: TestCategory = {
        ...addObj,
        active: true
      }

      // await this.afs.collection('TestCategories').add(newObj); // TODO check fs
      await addDoc(collection(this.afs, 'TestCategories'), newObj);
      return
    } catch (err) {
      console.log(err)
      this._handleError();
      throw new Error();
    }


  }

  public editTestCategory(id: string, editObj: TestCategory) {
    return new Promise((resolve, reject) => {
      // this.afs.collection('TestCategories').doc(id).update(editObj) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `TestCategories/${id}`), editObj, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public archiveTestCategory(id: string) {
    return new Promise((resolve, reject) => {
      // this.afs.collection('TestCategories').doc(id).update({ active: false }) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `TestCategories/${id}`), { active: false }, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public changeTestCategoryOrder(groups: TestCategory[]): Promise<string> {
    return new Promise((resolve, reject) => {
      // const batch = this.afs.firestore.batch();
      // const ref = this.afs.firestore.collection('TestCategories'); // TODO check fs
      const batch = writeBatch(this.afs)
      const ref = collection(this.afs, 'TestCategories');
      groups.forEach((item, index) => {
        if (item.order !== index) {
          // batch.update(ref.doc(item.id), { order: index }); // TODO check fs
          batch.update(doc(ref, item.id), { order: index });
        }
      });
      batch.commit()
        .then(() => {
          resolve('success');
        })
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public getTestCategfgoryDropDown(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      if (this._testCategories.length > 0) {
        resolve(this._testCategories.map(el => {
          return { value: el.id, label: el.name };
        }));
      } else {
        this.testCategories$.pipe(take(1)).toPromise()
          .then(categories => {
            resolve(categories.map(el => {
              return { value: el.id, label: el.name };
            }));
          }).catch(() => {
            reject('error');
            this._handleError();
          });
      }
    });
  }

  public getTestCategories(): Promise<FitnessCategory[]> {
    return new Promise((resolve, reject) => {
      if (this._testCategories.length > 0) {
        resolve(this._testCategories);
      } else {
        this.testCategories$.pipe(take(1)).toPromise()
          .then(categories => {
            resolve(categories);
          }).catch(() => {
            reject('error');
            this._handleError();
          });
      }
    });
  }



  public getTestCategoryLabel(id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this._testCategories.length > 0) {
        resolve(this._testCategories.find(el => {
          return el.id === id;
        }).name);
      } else {
        this.testCategories$.pipe(take(1)).toPromise()
          .then(categories => {
            resolve(this._testCategories.find(el => {
              return el.id === id;
            }).name);
          }).catch(() => {
            reject('error');
            this._handleError();
          });
      }
    });
  }

  public getAnatomyCategories(): Promise<AnatomyCategory[]> {
    return new Promise((resolve, reject) => {
      if (this._anatomyCategories.length > 0) {
        resolve(this._anatomyCategories);
      } else {
        this.anatomyCategories$.pipe(take(1)).toPromise()
          .then(categories => {
            resolve(categories);
          }).catch(() => {
            reject('error');
            this._handleError();
          });
      }
    });
  }

  private _handleError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please try again later' });
  }

  public getTypes(): SelectItem[] {
    return [
      { label: 'Athlete', value: 'athlete' },
      { label: 'Group Administrator', value: 'groupAdmin' },
    ];
  }

  public getCountries(): SelectItem[] {
    return [
      { label: 'South Africa', value: 'ZA' }
    ];
  }

  public getCountryLabel(value: string): string {
    return this.getCountries().find(el => {
      return el.label === value;
    }).value;
  }

  public getProvinces(): SelectItem[] {
    return [
      { label: 'Eastern Cape', value: 'EC' },
      { label: 'Free State', value: 'FS' },
      { label: 'Gauteng', value: 'GA' },
      { label: 'KwaZulu-Natal', value: 'KN' },
      { label: 'Limpopo', value: 'LP' },
      { label: 'Mpumalanga', value: 'MP' },
      { label: 'Northern Cape', value: 'NC' },
      { label: 'North West', value: 'NW' },
      { label: 'Western Cape', value: 'WC' },
    ];
  }

  public getProvinceLabel(value: string): string {
    return this.getProvinces().find(el => {
      return el.value === value;
    }).label;
  }

  public getPracticeType(): SelectItem[] {
    return [
      { label: 'Doctor', value: 'doctor' },
      { label: 'Physiotherapist', value: 'physiotherapist' },
      { label: 'Biokineticist', value: 'biokeneticist' },
      { label: 'Sports Scientist', value: 'sportsScientist' },
      { label: 'Athletic trainer', value: 'athleticTrainer' },
      { label: 'Gym instructor', value: 'gymInstructor' }
    ];
  }

  public getPracticeTypeLabel(value: string): string {
    return this.getPracticeType().find(el => {
      return el.value === value;
    }).label;
  }

  // anatomy categories
  public async addAnatomyCategory(addObj: AnatomyCategory) {
    try {

      const newObj: AnatomyCategory = {
        ...addObj,
        active: true, 
        createdOn: new Date(),
      }

      // await this.afs.collection('AnatomyCategories').add(newObj); // TODO check fs
      await addDoc(collection(this.afs, 'AnatomyCategories'), newObj);
      return
    } catch (err) {
      console.log(err)
      this._handleError();
      throw new Error();
    }
  }

  public updateAnatomyCategory(id: string, updateObj: AnatomyCategory) {
    const newObj: AnatomyCategory = {
      ...updateObj,
      updatedOn: new Date(),
    }

    return new Promise((resolve, reject) => {
      // this.afs.collection('AnatomyCategories').doc(id).update(newObj) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `AnatomyCategories/${id}`), newObj, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public changeAnatomyCategoryOrder(groups: AnatomyCategory[]): Promise<string> {
    return new Promise((resolve, reject) => {
      // const batch = this.afs.firestore.batch();
      // const ref = this.afs.firestore.collection('AnatomyCategories'); // TODO check fs
      const batch = writeBatch(this.afs)
      const ref = collection(this.afs, 'AnatomyCategories');
      groups.forEach((item, index) => {
        if (item.order !== index) { 
          // batch.update(ref.doc(item.id), { order: index }); // TODO check fs
          batch.update(doc(ref, item.id), { order: index });
        }
      });
      batch.commit()
        .then(() => {
          resolve('success');
        })
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public archiveAnatomyCategory(id: string) {
    return new Promise((resolve, reject) => {
      // this.afs.collection('AnatomyCategories').doc(id).update({ active: false }) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `AnatomyCategories/${id}`), { active: false }, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  // Equipment categories
  public async addEquipmentCategory(addObj: EquipmentCategory) {
    try {

      const newObj: EquipmentCategory = {
        ...addObj,
        active: true, 
        createdOn: new Date(),
      }

      // await this.afs.collection('EquipmentCategories').add(newObj); // TODO check fs
      await addDoc(collection(this.afs, 'EquipmentCategories'), newObj);
      return
    } catch (err) {
      console.log(err)
      this._handleError();
      throw new Error();
    }
  }

  public updateEquipmentCategory(id: string, updateObj: EquipmentCategory) {
    const newObj: EquipmentCategory = {
      ...updateObj,
      updatedOn: new Date(),
    }

    return new Promise((resolve, reject) => {
      // this.afs.collection('EquipmentCategories').doc(id).update(newObj) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `EquipmentCategories/${id}`), newObj, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public changeEquipmentCategoryOrder(groups: EquipmentCategory[]): Promise<string> {
    return new Promise((resolve, reject) => {
      // const batch = this.afs.firestore.batch();
      // const ref = this.afs.firestore.collection('EquipmentCategories');
      const batch = writeBatch(this.afs)
      const ref = collection(this.afs, 'EquipmentCategories'); // TODO check fs
      groups.forEach((item, index) => {
        if (item.order !== index) {
          // batch.update(ref.doc(item.id), { order: index }); // TODO check fs
          batch.update(doc(ref, item.id), { order: index });
        }
      });
      batch.commit()
        .then(() => {
          resolve('success');
        })
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }

  public archiveEquipmentCategory(id: string) {
    return new Promise((resolve, reject) => {
      // this.afs.collection('EquipmentCategories').doc(id).update({ active: false }) // TODO check fs
      //   .then(() => resolve('success'))
      //   .catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `EquipmentCategories/${id}`), { active: false }, { merge: true })
        .then(() => resolve('success'))
        .catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }
}
