
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Subscription } from 'rxjs';

import { take } from 'rxjs/operators';
import { User } from './interfaces.service';

import {
  Firestore, //constructor
  collection,
  doc,
  collectionData, //subscription
  query, // query<Cast>(collection, where()), {idField: 'id'})
  where,
  orderBy,
  getDoc, //promise
  addDoc,
  setDoc,
  getDocs,
  docData,
  onSnapshot, //batch
} from '@angular/fire/firestore';
import {
  Auth,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  authState,

} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private canLogin = false;

  public userDetailsObs = new BehaviorSubject<User>({});
  public gotAuthResponse = new BehaviorSubject<boolean>(false);
  public loggedIn$ = new BehaviorSubject<boolean>(false);

  private _userType = 'user';
  private _userSub: Subscription;

  // store the URL so we can redirect after logging in
  public redirectUrl: string;

  private _usersCollection: any;
  authSub: Subscription;
  constructor(
    private router: Router,
    // private afAuth: AngularFireAuth,
    private afAuth: Auth,

    // private afs: AngularFirestore,
    private afs: Firestore,
  ) {
    // this._usersCollection = this.afs.collection<User>('Users'); // TODO check fs
    this._usersCollection = getDocs(collection(this.afs, 'Users'));
    // this.afAuth.authState // TODO check fs
    this.authSub = authState(this.afAuth)
      .subscribe(user => {
                if (user) {
          this.canLogin = true;
          this.loggedIn$.next(true);

          this.getUserDetails(user.uid)
            .then(userDetails => {
              this.gotAuthResponse.next(true);
              if (userDetails.archived) {
                console.log('user is archived! logging user out...');
                this.logout();
              }
              if (this.redirectUrl === '/' || !this.redirectUrl) {
                // User type check and then route
                if (userDetails.superAdmin || this._userType === 'groupAdmin') {
                  this.router.navigate(['/challenges']);
                } else if (this._userType === 'athlete') {
                  if (!userDetails.gender) { // Profile not yet complete
                    this.router.navigate(['/users/me']);
                  } else {
                    this.router.navigate(['/challenges']);
                  }
                } else {
                  console.log('no route to take');
                }
              } else {
                this.router.navigate([this.redirectUrl]);
                this.redirectUrl = null;
              }
            });
        } else { // Not signed in
          this.gotAuthResponse.next(true);
          this.resetAuth();
        }
      });
  }

  private resetAuth() {
    this.canLogin = false;
    this.loggedIn$.next(false);
    this.userDetailsObs.next({ scopeId: ['dummy'] });
    this.redirectUrl = null;
    if (this._userSub) {
      this._userSub.unsubscribe();
    }
  }

  public async login(email, password) {
        return new Promise((resolve, reject) => {
      // this.afAuth.signInWithEmailAndPassword(email, password) // TODO check fs
      signInWithEmailAndPassword(this.afAuth, email, password)
        .then(resp => {
          resolve('success');
          // The subscriber will catch when ok
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public logout(): void {
    this.redirectUrl = null;

    this.afAuth.signOut()
      .then(resp => {
        this.router.navigate(['/auth/signin']);
        this.resetAuth();
      });
  }

  public checkAuthStatus() {
        return this.canLogin;
  }

  public checkForUser() {
        if (this._userType === 'user') {
      return true;
    }
    return false;
  }

  public checkForAdmin() {
        if (this._userType === 'groupAdmin') {
      return true;
    }
    return false;
  }



  private async getUserDetails(uid: string): Promise<User> {
    // this.subscribeToUser(uid);
    // const docRef = doc(this._usersCollection, uid);
    // const docSnap = await getDoc(docRef);
  
    // if (docSnap.exists()) {
    //   const user = docSnap.data() as User;
    //   this.userDetailsObs.next(user);
    //   this._userType = user.type;
    //   return user;
    // } else {
    //   throw new Error(`No such user: ${uid}`);
    // }
    this.subscribeToUser(uid);
    const docRef = getDoc(doc(this.afs, 'Users', uid));
    const docSnap = await docRef;
    if (docSnap.exists()) {
      const user = docSnap.data() as User;
      this.userDetailsObs.next(user);
      this._userType = user.type;
      return user;
    } else {
      throw new Error(`No such user: ${uid}`);
    }
  }

  private subscribeToUser(uid: string) {
    if (this._userSub) {
      this._userSub.unsubscribe();
    }
  
    // const docRef = doc(this._usersCollection, uid);
    // const unsubscribe = onSnapshot(docRef, (docSnap) => {
    //   if (docSnap.exists()) {
    //     const user = docSnap.data() as User;
    //     this.userDetailsObs.next(user);
    //     this._userType = user.type;
    //   } else {
    //     console.log(`No such user: ${uid}`);
    //   }
    // });
    const docRef = doc(this.afs, 'Users', uid);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const user = docSnap.data() as User;
        this.userDetailsObs.next(user);
        this._userType = user.type;
      } else {
        console.log(`No such user: ${uid}`);
      }
    });

    this._userSub = new Subscription(unsubscribe);
  }


  public resetPassword(email: string) {
        return new Promise((resolve, reject) => {
      // this.afAuth.sendPasswordResetEmail(email).then(() => { // TODO check fs
      sendPasswordResetEmail(this.afAuth, email).then(() => {
        // Email sent.
        resolve({ status: 'success', message: 'Password reset mail sent' });
      }, error => {
        reject({ status: 'failure', message: error });
      });
    });
  }

  ngOnDestroy() {
    if (this._userSub) {
      this._userSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }




}
