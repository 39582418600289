import { LayoutHomeComponent } from './layout-home/layout-home.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from './services/auth.guard.service';
import { FrontendLayoutComponent } from './layout-frontend/frontend-layout.component';


export const routes: Routes = [
    {path: '',
    canActivate: [AuthGuard],
    component: LayoutHomeComponent,
    data: {pageTitle: 'Me Vs You'},
    children: [
        {path: 'athlete', loadChildren: () => import('./athlete/athlete.module').then(m => m.AthleteModule)},
        {path: 'super-admin', loadChildren: () => import('./super-admin/super.module').then(m => m.SuperAdminModule)},
        {path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule)},
        {path: 'assessments', loadChildren: () => import('./assessments/assessments.module').then(m => m.AssessmentsModule)},
        {path: 'challenges', loadChildren: () => import('./community-challenges/community-challenges.module').then(m => m.CommunityChallengesModule)},
        {path: 'channels', loadChildren: () => import('./channels/channels.module').then(m => m.ChannelsModule)},
    ]},
    {path:'s', loadChildren: () => import('./share/share.module').then(m => m.ShareModule)},
    {path: '', component: FrontendLayoutComponent, children: [
        {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
        {path: '', redirectTo: '/assessments', pathMatch: 'full'}
    ]},
   

];

// tslint:disable-next-line: max-line-length
// export const AppRoutes: ModuleWithProviders<any> = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy', initialNavigation: 'enabledNonBlocking'});
export const AppRoutes: ModuleWithProviders<any> = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledNonBlocking'});

