import { ThemeService } from './../services/theme.service';
import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu',
  template: `
        <ul app-submenu [item]="model" root="true" class="layout-menu" [reset]="reset" visible="true" parentActive="true"></ul>
    `
})
export class AppMenuComponent implements OnInit {

  @Input() reset: boolean;
  @Input() type: string; // menu, admin, superAdmin, demo, groupAdmin
  model: MenuItem[] = [];

  menu: MenuItem[] = [];
  admin: MenuItem[] = [];
  superAdmin: MenuItem[] = [];
  groupAdmin: MenuItem[] = [];

  constructor() { }

  ngOnInit() {

    this.menu = [
      { label: 'Challenges', routerLink: ['/challenges/'] },      
    ];

    this.groupAdmin = [
      { label: 'Channels', routerLink: ['/channels/'] },      
    ];

    this.superAdmin = [
      { label: 'Tests', routerLink: ['/super-admin/tests'] },
      { label: 'Leaderboards', routerLink: ['/super-admin/leaderboards'] },
      { label: 'Abuses', routerLink: ['/super-admin/abuses'] },
      { label: 'Users', routerLink: ['/users/athletes'] },
      { label: 'Locations', routerLink: ['/super-admin/locations']},
      { label: 'Gym Locations', routerLink: ['/super-admin/gym-locations']},
      { label: 'Master Data', expanded: true, items: [
          { label: 'Age Groups', routerLink: ['/super-admin/master-data/age-groups'] },
          { label: 'Anatomy Categories', routerLink: ['/super-admin/master-data/anatomy-categories'] },
          { label: 'Equipment Categories', routerLink: ['/super-admin/master-data/equipment-categories'] },
          { label: 'Fitness Categories', routerLink: ['/super-admin/master-data/fitness-categories'] },
          { label: 'Special Groups', routerLink: ['/super-admin/master-data/comparison-groups'] },
          { label: 'Sport Filters', routerLink: ['/super-admin/master-data/sport-filters'] },
        ]
      },
      { label: 'Bulk Emails', routerLink: ['/super-admin/bulk-emails'] },
      { label: 'Home items', routerLink: ['/super-admin/home-items']},
      { label: 'Push Notification', routerLink: ['/super-admin/push-notifications']},      
      { label: 'Media Library', routerLink: ['/super-admin/media-library']},
    ];

    switch (this.type) {
      case 'menu':
        this.model = this.menu;
        break;
      case 'admin':
        this.model = this.admin;
        break;
      case 'superAdmin':
        this.model = this.superAdmin;
        break;
      case 'groupAdmin':
        this.model = this.groupAdmin;
        break;
      default:
        break;
    }


  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *ngIf="child.visible === false ? false : true">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
                   *ngIf="!child.routerLink" [ngClass]="child.styleClass"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i [ngClass]="child.icon"></i>
                    <span>{{child.label}}</span>
                    <i class="fa fa-fw fa-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>

                <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                    [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i [ngClass]="child.icon"></i>
                    <span>{{child.label}}</span>
                    <i class="fa fa-fw fa-angle-down" *ngIf="child.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>
                <div class="layout-menu-tooltip">
                  <div class="layout-menu-tooltip-arrow"></div>
                  <div class="layout-menu-tooltip-text">{{child.label}}</div>
                </div>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                    [@children]="(themeService.isSlim()|| themeService.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
  animations: [
    trigger('children', [
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*',
        'z-index': 100
      })),
      state('hidden', style({
        height: '0px',
        'z-index': '*'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppSubMenuComponent {

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _parentActive: boolean;

  _reset: boolean;

  activeIndex: number;

  constructor(public themeService: ThemeService) { }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (this.root) {
      this.themeService.menuHoverActive = !this.themeService.menuHoverActive;
    }
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = (this.activeIndex === index) ? null : index;

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(() => {
        this.themeService.moveLayoutMenuScrollBar.next('move now');
        //   this.app.layoutMenuScrollerViewChild.moveBar();
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.themeService.isHorizontal() || this.themeService.isSlim()) {
        this.themeService.resetMenu = true;
      } else {
        this.themeService.resetMenu = false;
      }

      this.themeService.overlayMenuActive = false;
      this.themeService.staticMenuMobileActive = false;
      this.themeService.menuHoverActive = !this.themeService.menuHoverActive;
    }
  }

  onMouseEnter(index: number) {
    if (this.root && this.themeService.menuHoverActive && (this.themeService.isHorizontal() || this.themeService.isSlim())
      && !this.themeService.isMobile() && !this.themeService.isTablet()) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.themeService.isHorizontal() || this.themeService.isSlim())) {
      this.activeIndex = null;
    }
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}
