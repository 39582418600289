import { MessageService } from 'primeng/api';
import { OnDestroy, Injectable } from '@angular/core';
import { Subscription, Observable, Subject, BehaviorSubject, from } from 'rxjs';
import { User, DataScope } from './interfaces.service';
import { ScopesService } from './scopes.service';
import { filter, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import {
  Auth,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  authState,
  createUserWithEmailAndPassword,
} from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Firestore, //constructor
  collection,
  doc,
  collectionData, //subscription
  query, // query<Cast>(collection, where()), {idField: 'id'})
  where,
  orderBy,
  getDoc, //promise
  addDoc,
  setDoc,
  getDocs, //batch
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UsersService implements OnDestroy {
  // private database: any;
  private _authSub: Subscription;
  private _scopeInUse: DataScope;
  public usersObs: Observable<User[]>;
  public athletesObs: Observable<User[]>;

  public athleteSelectedObs = new BehaviorSubject<User>({});

  public singleAthleteId = new BehaviorSubject<string>('');
  public singleAthleteObs: Observable<User>;


  constructor(
    // private fns: AngularFireFunctions,
    // private afs: AngularFirestore,
    // private auth: AngularFireAuth,
    private afs: Firestore,
    private auth: Auth,
    private fns: Functions,
    private scopesService: ScopesService,
    private messageService: MessageService,
    private authService: AuthService
  ) {
    this.usersObs = this.scopesService.activeScope
      .pipe(
        filter(el => {
          return el.id != null;
        }), switchMap((scope: DataScope) => {
          this._scopeInUse = scope;
          // TODO check fs 
          // return afs.collection('Users', ref => {
          //   let query: CollectionReference | Query = ref;

          //   query = query.orderBy('nameSurname')
          //     .where('scopeId', 'array-contains', scope.id);

          //   return query;
          // }).valueChanges();
          return collectionData(query(collection(this.afs, 'Users'), orderBy('nameSurname'), where('scopeId', 'array-contains', scope.id)), {idField: 'id'});

        })
      );

    this.athletesObs = this.scopesService.activeScope
      .pipe(
        filter(el => {
          return el.id != null;
        }), switchMap((scope: DataScope) => {

          this._scopeInUse = scope;
          // TODO check
          // return afs.collection('Users', ref => {
          //   let query: CollectionReference | Query = ref;

          //   query = query.orderBy('nameSurname').where('scopeId', 'array-contains', scope.id);
            
          //   return query;
          // }).valueChanges();
          return collectionData(query(collection(this.afs, 'Users'), orderBy('nameSurname'), where('scopeId', 'array-contains', scope.id)), {idField: 'id'});

        })
      );

    this.singleAthleteObs = this.singleAthleteId
      .pipe(
        filter(el => {
          return el != null;
        }), switchMap((athleteId: string) => {
          // return afs.collection('Users').doc(athleteId).valueChanges(); // TODO check fs
          return from(getDoc(doc(this.afs, 'Users', athleteId))).pipe(switchMap(doc => {
            if (doc.exists()) {
              return new Observable<User>(observer => {
                observer.next(doc.data());
              });
            } else {
              return new Observable<User>();
            }
          }));
        })
      );

    this._authSub = this.authService.userDetailsObs
      .subscribe(user => {
        if (user.type === 'athlete') {
          this.athleteSelectedObs.next(user);
        }
      });
  }

  public getUser(userId: string): Promise<User> {
    // TODO check fs
    // return new Promise((resolve, reject) => {
    //   this.afs.collection('/Users').doc(userId).get().toPromise()
    //     .then(user => {
    //       resolve(user.data());
    //     }).catch(() => {
    //       reject('error');
    //       this._handleError();
    //     });
    // });
    return new Promise(async resolve => {
      const user = await getDoc(doc(this.afs, `Users/${userId}`))
      .then(user => {
        resolve(user.data());
      }).catch(() => {
        this._handleError();
      });
      
    });
  }


  public editUser(id: string, userObj: User) {
    return new Promise(async (resolve, reject) => {
      if (this._scopeInUse) {
        this._scopeInUse = this.scopesService.activeScope.value;
      }

      // const ageGroupName = userObj.ageGroupId ? await this.masterDataService.getAgeGroupLabel(userObj.ageGroupId) : null;
      const countryName = userObj.country ? userObj.country : null;
      const provinceName = userObj.province ? userObj.province : null;

      const updateObj: User = Object.assign({}, userObj,

        { countryName },
        { provinceName },
        { updatedOn: new Date() },
      );
        // TODO check fs
      // this.afs.collection('Users').doc(id).update(updateObj)
      //   .then(() => {
      //     resolve('success');
      //   }).catch(() => {
      //     reject('error');
      //     this._handleError();
      //   });
      setDoc(doc(this.afs, `Users/${id}`), updateObj, { merge: true })
        .then(() => {
          resolve('success');
        }).catch(() => {
          reject('error');
          this._handleError();
        });
    });
  }



  public createUser(email: string, password: string, detailsObj): Promise<{status: 'new' | 'existing', uid?: string, user?: User}> {
    return new Promise((resolve, reject) => {
      if (!this._scopeInUse) {
        this._scopeInUse = this.scopesService.activeScope.value;
      }
      // const callable = this.fns.httpsCallable('createUser'); // TODO check fs
      const callable: any = httpsCallable(this.fns, 'createUser');

      // tslint:disable-next-line:object-literal-shorthand
      const f = callable({ email: email, password: password, displayName: detailsObj.nameSurname })
        .subscribe(resp => {
          if (resp.status === 'ok') {
            const uid = resp.response.uid;
            const updateObj: User = {
              ...detailsObj,
              id: uid,
              createdOn: new Date(),
              archived: false,
              scopeId: [this._scopeInUse.id]
            };
            // TODO check fs
            // this.afs.collection('Users').doc(uid).set(updateObj)
            //   .then(() => {
            //     resolve({status: 'new', uid});
            //   });
            setDoc(doc(this.afs, `Users/${uid}`), updateObj)
              .then(() => {
                resolve({status: 'new', uid});
              });
          } else if (resp.status === 'existing') {

            resolve({status: 'existing', user: resp.response})
          } else {
            this._handleError();
            reject(resp.response);
          }
        });
    });
  }

  async signUpUser(formData) {
    try {
      // const user = await this.auth.createUserWithEmailAndPassword(formData.email, formData.password); // TODO check fs
      const user = await createUserWithEmailAndPassword(this.auth, formData.email, formData.password);
      
      const userObj: User = {
        id: user.user.uid,
        userName: user.user.email,
        nameSurname: formData.nameSurname,
        type: 'athlete',
        typeName: 'Athlete',
        createdOn: new Date(),
        archived: false,
      };

      // await this.afs.collection('/Users').doc(user.user.uid).set(userObj); // TODO check fs
      await addDoc (collection(this.afs, 'Users'), userObj);
      return user;
    } catch (err) {
      this._handleError(err);
      throw new Error(err.message);
    }
  }

  // async athleteLinkToPractice(athleteId: string, scopeId: string) {
  //   try {
  //     await this.afs.collection('/Users').doc(athleteId).update({ scopeId: firebase.firestore.FieldValue.arrayUnion(scopeId) });
  //   } catch {
  //     this._handleError();
  //     throw new Error('error');
  //   }
  // }

  // async athleteUnlinkToPractice(athleteId: string, scopeId: string) {
  //   try {
  //     await this.afs.collection('/Users').doc(athleteId).update({ scopeId: firebase.firestore.FieldValue.arrayRemove(scopeId) });
  //   } catch {
  //     this._handleError();
  //     throw new Error('error');
  //   }
  // }
  

  // void createUserDeletionRequest() async {
  //   try {
  //     MvYUser? _currentUser = getIt.get<AuthService>().userBS.value;
  //     if (_currentUser == null) {
  //       throw new Error();
  //     }

  //     Map<String, dynamic> updateObj = {
  //       'userId': _currentUser.id,
  //       'nameSurname': _currentUser.nameSurname,
  //       'userName': _currentUser.userName,
  //       'newDate': DateTime.now(),
  //       'status': 'New'
  //     };
  //     analytics.logEvent(name: 'profile_delete_requested');
  //     await FirebaseFirestore.instance
  //         .collection('UserDeletionRequest')
  //         .add(updateObj);

  //     getIt.get<AuthService>().logout();
  //     return null;
  //   } catch (_) {
  //     throw new Error();
  //   }
  // }

  public async deleteUserAccount (userObj) {
    try {
        const updateObj = {
          'userId': userObj.id,
          'nameSurname': userObj.nameSurname,
          'userName': userObj.userName,
          'newDate': new Date(),
          'status': 'New'
        }
        await addDoc(collection(this.afs, `UserDeletionRequest`), updateObj);
        return 'success'

    } catch (err) {
      this._handleError(err);
    }
  }



  private _handleError(err = {}) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please try again later' });
  }

  ngOnDestroy() {
    if (this._authSub) {
      this._authSub.unsubscribe();
    }
  }
}

