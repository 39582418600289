<div class="layout-breadcrumb">
    <ul>
        <li>
            <a routerLink="/challenges">
                <i class="fa fa-home"></i>
            </a>
        </li>
        <li>/</li>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <li>
                <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
                <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
            </li>
            <li *ngIf="!last">/</li>
        </ng-template>
    </ul>

    <!-- <div class="layout-breadcrumb-options">
        <div>
            <span class="dropdown-beadcrumb" *ngIf="user.superAdmin">
            <p-dropdown [autoDisplayFirst]="false" placeholder="Select Scope" [options]="scopesDD" (onChange)="changeScope($event)" [ngModel]="activeScopeId"></p-dropdown>
        </span>

            <span class="dropdown-beadcrumb" *ngIf="activeScopeId!='super'">
                <p-dropdown [autoDisplayFirst]="false" placeholder="Select Athlete" [options]="athletesDD" (onChange)="onChangeAthlete($event)" [ngModel]="selectedAthleteId"></p-dropdown>
            </span>
        </div>

    </div> -->
</div>