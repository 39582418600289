<div class="layout-wrapper" (click)="onLayoutClick()" [ngClass]="{'layout-horizontal': themeService.isHorizontal(),
                 'layout-overlay': themeService.isOverlay(),
                 'layout-static': themeService.isStatic(),
                 'layout-slim': themeService.isSlim(),
                 'layout-static-inactive': themeService.staticMenuDesktopInactive,
                 'layout-mobile-active': themeService.staticMenuMobileActive,
                 'layout-overlay-active': themeService.overlayMenuActive}">

    <app-topbar></app-topbar>
    <div class="layout-menu-container" (click)="onMenuClick($event)">
        <p-scrollPanel #layoutMenuScroller [style]="{height: '100%'}">
            <div class="layout-menu-content">
                <div>
                    <div class="layout-menu-title">Menu</div>
                    <app-menu [reset]="themeService.resetMenu" type="menu"></app-menu>
                </div>

                <div *ngIf="user.type === 'groupAdmin' || user.superAdmin">
                    <!-- <div class="layout-menu-title">GROUP ADMIN</div> -->
                    <app-menu [reset]="themeService.resetMenu" type="groupAdmin"></app-menu>
                </div>

                <div *ngIf="user.superAdmin">
                    <div class="layout-menu-title">SUPER ADMIN</div>
                    <app-menu [reset]="themeService.resetMenu" type="superAdmin"></app-menu>
                </div>
            </div>
        </p-scrollPanel>
    </div>

    <div class="layout-content">

        <!-- <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog> -->
        <p-confirmPopup></p-confirmPopup>
        <app-breadcrumb></app-breadcrumb>

        <div class="layout-content-container">
            <p-toast position="top-right"></p-toast>
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
        <div class="layout-mask" *ngIf="themeService.staticMenuMobileActive"></div>
    </div>
</div>