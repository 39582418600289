import {ConfirmationService} from 'primeng/api';

import { MessageService } from 'primeng/api';
import { FrontendLayoutComponent } from './layout-frontend/frontend-layout.component';

import {  HttpClientModule } from '@angular/common/http';

import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';


import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routes';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';

import { AppComponent } from './app.component';


import { environment} from '../environments/environment';

import { LayoutModule } from './layout-home/layout.module';



@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    AppRoutes,
    BrowserAnimationsModule,
    HttpClientModule,
    DynamicDialogModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
  

 
    LayoutModule
  ],
  declarations: [
    AppComponent,
    
    FrontendLayoutComponent,
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    MessageService, ConfirmationService, DialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
