import { ThemeService } from './../services/theme.service';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ScrollPanel } from 'primeng/scrollpanel';
import { Subscription } from 'rxjs';
import { User, DataScope } from '../services/interfaces.service';
import { AuthService } from '../services/auth.service';
import { ScopesService } from '../services/scopes.service';

@Component({
  selector: 'app-layout-home',
  templateUrl: './layout-home.component.html',
  styleUrls: ['./layout-home.component.css']
})
export class LayoutHomeComponent implements AfterViewInit, OnInit, OnDestroy {
  userSub: Subscription;
  user: User = {
    type: 'athlete'
  };

  darkTheme = true;
  layoutMenuScroller: HTMLDivElement;

  activeScope: DataScope;
  scopeSub: Subscription;

  constructor(public themeService: ThemeService,
              private authService: AuthService,
              private scopesService: ScopesService) {
    this.darkTheme = this.themeService.darkTheme;
  }

  @ViewChild('layoutMenuScroller', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;
  moveLayoutScrollerSub: Subscription;

  ngOnInit(): void {
    this.moveLayoutScrollerSub = this.themeService.moveLayoutMenuScrollBar
      .subscribe(() => {
        this.layoutMenuScrollerViewChild.moveBar();
      });

    this.userSub = this.authService.userDetailsObs
      .subscribe((user: User) => {
        this.user = user;
      });

    this.scopeSub = this.scopesService.activeScope
      .subscribe(scope => {
        this.activeScope = scope;
      });
  }


  ngAfterViewInit() {
    setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
  }

  onLayoutClick() {
    if (!this.themeService.topbarItemClick) {
      this.themeService.activeTopbarItem = null;
      this.themeService.topbarMenuActive = false;
    }

    if (!this.themeService.menuClick) {
      if (this.themeService.isHorizontal() || this.themeService.isSlim()) {
        this.themeService.resetMenu = true;
      }

      if (this.themeService.overlayMenuActive || this.themeService.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.themeService.menuHoverActive = false;
    }

    this.themeService.topbarItemClick = false;
    this.themeService.menuClick = false;
  }



  onMenuClick(event: any) {
    this.themeService.menuClick = true;
    this.themeService.resetMenu = false;

    if (!this.themeService.isHorizontal()) {
      setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 500);
    }
  }

  hideOverlayMenu() {
    this.themeService.overlayMenuActive = false;
    this.themeService.staticMenuMobileActive = false;
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.scopeSub) {
      this.scopeSub.unsubscribe();
    }
  }

}
