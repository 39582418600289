<div class="topbar clearfix">
    <a href="#" class="topbar-logo">
        <img class="logo" routerLink="/" style="outline: none; cursor: pointer; width: auto; height: 100%; max-width: 200px;" alt="logo dark theme" src="/assets/images/logo-dark.png" />
    </a>
    <a id="menu-button" href="#" (click)="onMenuButtonClick($event)">
        <i class="fa fa-align-left"></i>
    </a>
    <a href="#" class="profile" (click)="onTopbarMenuButtonClick($event)">
        <span class="username">{{user.nameSurname}}</span>
        <img [src]="user.profileImageUrl" style="border-radius: 50%" />
        <i class="fa fa-angle-down"></i>
    </a>
    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-visible': themeService.topbarMenuActive}">
        <li role="menuitem">
            <a href="#" [routerLink]="['/users/me']">
                <i class="fa fa-fw fa-user"></i>
                <span>Profile</span>
            </a>
        </li>
        <li role="menuitem">
            <a style="cursor:pointer" (click)="onLogout()">
                <i class="fa fa-fw fa-sign-out"></i>
                <span>Logout</span>
            </a>
        </li>    
    </ul>
</div>