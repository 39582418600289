import { UsersService } from './../services/users.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { BreadcrumbService } from '../services/breadcrumb.service';
import { ScopesService } from '../services/scopes.service';
import { User } from '../services/interfaces.service';
import { AuthService } from '../services/auth.service';
import { MenuItem, SelectItem } from 'primeng/api';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  items: MenuItem[];
  activeScopesSub: Subscription;
  activeScopeId: string;
  scopesDD: SelectItem[];
  scopesSub: Subscription;

  userSub: Subscription;
  user: User = {
    superAdmin: false
  };

  athleteSub: Subscription;
  athletesDD: SelectItem[];


  athletesDb: User[] = [];
  selectedAthleteSub: Subscription;
  selectedAthleteId: string;

  constructor(
    public breadcrumbService: BreadcrumbService,
    private scopesService: ScopesService,
    private authService: AuthService,
    private usersService: UsersService
  ) {
    this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
      this.items = response;
    });
  }
  ngOnInit() {
    this.userSub = this.authService.userDetailsObs
      .subscribe((user: User) => {
        if (user) {
          this.user.superAdmin = user.superAdmin;
          this.user.type = user.type;
        }
      });

    this.activeScopesSub = this.scopesService.activeScope
      .subscribe(scope => {
        this.activeScopeId = scope.id;
        // console.log(scope);
      });

    this.scopesSub = this.scopesService.scopesBS
      .subscribe(scopes => {
        this.scopesDD = scopes.map(el => {
          return { value: el.id, label: el.name };
        });
      });

    this.athleteSub = this.usersService.athletesObs
      .subscribe(athletes => {
        this.athletesDb = athletes;
        this.athletesDD = athletes.map(el => {
          return { value: el.id, label: el.nameSurname };
        });
      });

    this.selectedAthleteSub = this.usersService.athleteSelectedObs
      .subscribe(user => {
        this.selectedAthleteId = user.id;
      });
  }

  changeScope(event) {
    this.scopesService.activeScopeId.next(event.value);
  }

  onChangeAthlete(event) {
    const athlete = this.athletesDb.find(el => el.id === event.value);
    this.usersService.athleteSelectedObs.next(athlete);
    // console.log(athlete);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.scopesSub) {
      this.scopesSub.unsubscribe();
    }
    if (this.activeScopesSub) {
      this.activeScopesSub.unsubscribe();
    }
  }
}
